import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Typography,
  List,
  Row,
  Col,
  Tooltip,
  Alert,
  Radio,
  Divider,
} from 'antd'
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'

import { loadOrdersFx, $orders, $ordersMeta } from '../../models/orders'
import { $selectedRestaurant } from '../../models/restaurants'
import { formatPhoneNumber } from '../../utils/helpers'
import { DAY_MONTH_FORMAT } from '../../utils/constants'

const { Text } = Typography
const periodOptions = [
  { label: 'За сутки', value: 'day' },
  { label: 'За месяц', value: 'month' },
  { label: 'За год', value: 'year' },
]

/**
 * Информационная панель заказов ресторана
 */
function OrderScreens() {
  const orders = useStore($orders)
  const ordersMeta = useStore($ordersMeta)
  const loading = useStore(loadOrdersFx.pending)
  const selectedRestaurant = useStore($selectedRestaurant)

  const [page, setPage] = useState(1)
  const [period, setPeriod] = useState('day')

  useEffect(() => {
    setPage(1)
  }, [selectedRestaurant, period])

  useEffect(() => {
    if (selectedRestaurant) {
      loadOrdersFx({ shopId: selectedRestaurant.id, page, perPage: 10, period })
    }
  }, [page, period, selectedRestaurant])

  const columns = [
    {
      title: 'Заказ',
      dataIndex: 'id',
      name: 'id',
      render: (_, { id }) => {
        return (
          <Text>
            <Text type="secondary">#</Text> <Text strong>{id}</Text>
          </Text>
        )
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id
        } else {
          return false
        }
      },
    },
    {
      title: 'Дата',
      name: 'date',
      render: (_, { createdAt }) => {
        const date = moment(createdAt).format(DAY_MONTH_FORMAT)
        return <Text>{date}</Text>
      },
    },
    {
      title: 'Адрес',
      name: 'address',
      render: (_, { address_line }) => {
        return <Text>{address_line}</Text>
      },
    },
    {
      title: 'Телефон',
      name: 'customer_contact',
      render: (_, { customer_contact }) => (
        <a href={`tel:${customer_contact}`}>
          {formatPhoneNumber(customer_contact)}
        </a>
      ),
    },
    {
      title: 'Сумма',
      name: 'sum',
      render: (_, { meta }) => (
        <Text>
          {meta.sum}&nbsp;<Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: 'Доставка',
      name: 'deliveryCost',
      render: (_, { meta }) => (
        <Text>
          {meta.deliveryCost}&nbsp;<Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: 'Итого',
      name: 'total',
      render: (_, { meta, payment_type }) => (
        <Text mark={payment_type === 1}>
          {Number(meta.sum) + Number(meta.deliveryCost)}&nbsp;
          <Text type="secondary">&#8381;</Text>
        </Text>
      ),
    },
    {
      title: (
        <Tooltip title="Статус">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: 'status',
      name: 'status',
      filters: [
        {
          text: 'Новый',
          value: 'new',
        },
        {
          text: 'Подтверждён',
          value: 'confirmed',
        },
        {
          text: 'Отменён',
          value: 'canceled',
        },
      ],
      filterMultiple: true,
      onFilter: (value, { status }) => status === value,
      render: (_, { status }) => {
        const type =
          status === 'new'
            ? 'warning'
            : status === 'confirmed'
            ? 'success'
            : 'danger'

        const icon =
          status === 'new' ? (
            <PlusOutlined />
          ) : status === 'confirmed' ? (
            <CheckOutlined />
          ) : (
            <CloseOutlined />
          )
        return <Text type={type}>{icon}</Text>
      },
    },
  ]

  return (
    <ScreenLayout>
      <PageHeader
        title={
          <Text>
            Заказы
            {selectedRestaurant && <Text>: {selectedRestaurant.title}</Text>}
          </Text>
        }
      />
      <Radio.Group
        options={periodOptions}
        onChange={({ target }) => setPeriod(target.value)}
        value={period}
        optionType="button"
      />
      <Divider />
      <Alert
        type="warning"
        message="Жёлтым цветом выделена стоимость заказа, оплаченного онлайн."
        style={{ marginBottom: 16 }}
      />

      <Table
        dataSource={orders}
        columns={columns}
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          showSizeChanger: false,
          current: page,
          pageSize: 10,
          total: ordersMeta?.totalResults,
          onChange: page => setPage(page),
        }}
        expandable={{
          expandedRowRender: ({ basket }) => {
            return (
              <List
                itemLayout="vertical"
                dataSource={basket}
                renderItem={({
                  id,
                  title,
                  qty,
                  price,
                  finalPrice,
                  options,
                }) => {
                  return (
                    <List.Item>
                      <Row gutter={16} style={{ width: '50%' }}>
                        <Col span={10}>
                          <Text>
                            {qty}&nbsp;&times;&nbsp;{title}
                          </Text>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          <Text>
                            {finalPrice || price}&nbsp;
                            <Text type="secondary">&#8381;</Text>
                          </Text>
                        </Col>
                      </Row>
                      {options.map(
                        ({ id: optionId, title, price, finalPrice }) => {
                          return (
                            <Row
                              key={`${id}-${optionId}`}
                              gutter={16}
                              style={{ width: '50%' }}
                            >
                              <Col span={10}>
                                <Text type="secondary">-&nbsp;{title}</Text>
                              </Col>
                              <Col span={4} style={{ textAlign: 'right' }}>
                                <Text type="secondary">
                                  +&nbsp;{finalPrice || price}&nbsp;&#8381;
                                </Text>
                              </Col>
                            </Row>
                          )
                        },
                      )}
                    </List.Item>
                  )
                }}
              />
            )
          },
          rowExpandable: ({ basket }) => !!basket,
        }}
        bordered
      />
    </ScreenLayout>
  )
}

export default OrderScreens
