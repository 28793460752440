import axios from 'axios'
import message from 'antd/lib/message'
import { $blacklist, banCustomerFx, loadBlackListFx, unbanCustomerFx } from '.'
import { $token } from '../user'

loadBlackListFx.use(async restaurantId => {
  const { data } = await axios.get(`/api/owner/shops/${restaurantId}/ban`, {
    headers: { Authorization: `Bearer ${$token.getState()}` },
  })

  return data
})

banCustomerFx.use(async ({ restaurantId, customer }) => {
  const { data } = await axios.post(
    `/api/owner/shops/${restaurantId}/ban`,
    { ...customer },
    { headers: { Authorization: `Bearer ${$token.getState()}` } },
  )

  if (data.code === 'error') {
    message.error(data.message)
    throw new Error(data.message)
  }

  if (data.code === 'ok') {
    message.success('Пользователь заблокирован')
    return data.data
  }
})

unbanCustomerFx.use(async ({ restaurantId, banId }) => {
  const { data } = await axios.delete(
    `/api/owner/shops/${restaurantId}/ban/${banId}`,
    {
      headers: { Authorization: `Bearer ${$token.getState()}` },
    },
  )

  if (data.code === 'ok') {
    return { id: banId }
  } else {
    throw new Error('Произошла ошибка')
  }
})

$blacklist
  .on(loadBlackListFx.done, (_, { result }) => {
    if (result.code === 'ok') {
      return result.data
    }
  })
  .on(banCustomerFx.done, (blacklist, { result }) => {
    return [...blacklist, result]
  })
  .on(unbanCustomerFx.done, (blacklist, { result }) =>
    blacklist.filter(({ id }) => id !== result.id),
  )
