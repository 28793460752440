import axios from 'axios'
import { sample } from 'effector'
import { loadOrdersFx, $orders, $ordersMeta } from './'
import { $token } from '../user'

loadOrdersFx.use(async ({ shopId, period, page, perPage = 10 }) => {
  const queryParams = `period=${period}&page=${page}&perPage=${perPage}`

  const { data } = await axios.get(
    `/api/owner/shops/${shopId}/orders?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )

  return data
})

$ordersMeta.on(loadOrdersFx.done, (_, { result }) => {
  if (result.code === 'ok') {
    return result.meta
  }
})

sample({
  source: loadOrdersFx.done,
  fn: ({ result }) => {
    if (result.code === 'ok') {
      const orders = result.data.map(order => {
        const { address, customer_address, takeaway, takeawayAddress } = order
        const address_line = takeaway
          ? `Самовывоз: ${takeawayAddress}`
          : address && address.line
          ? `${address.line.trim()}${
              address.office ? `, ${address.office}` : ''
            }`
          : customer_address || ''
        return {
          ...order,
          address_line,
          key: `order-${order.id}`,
        }
      })
      return orders
    }
    return []
  },
  target: $orders,
})
