import axios from 'axios'
import { sample } from 'effector'
import { $token } from '../user'
import { updateSpecialGiftFx } from '../specials'
import { loadDishesFx } from '../dishes'
import { loadDishCategoriesFx } from '../dishCategories'
import { loadDishOptionsFx } from '../dishOptions'
import { loadOptionGroupsFx } from '../dishOptionGroups'
import {
  loadReastaurantsFx,
  setAvailablityFx,
  setDeliveryServiceStatusFx,
  $restaurants,
  selectedRestaurantKey,
  restaurantSelected,
  $selectedRestaurant,
  setAvgDeliveryTimeFx,
  setAvgPreparationTimeFx,
} from './'
import { userLoggedOut } from '../user'
import { message } from 'antd'

loadReastaurantsFx.use(async () => {
  const res = await axios.get('/api/owner/shops/', {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

setAvailablityFx.use(async (restaurantId) => {
  const { status } = await axios.put(
    `/api/owner/shops/${restaurantId}/available`,
    {},
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    }
  )

  if (status === 200) {
    return restaurantId
  } else {
    const errorText = 'Не удалось изменить статус ресторана'
    message.error(errorText)
    throw new Error(errorText)
  }
})

setDeliveryServiceStatusFx.use(async (restaurantId) => {
  const { status } = await axios.put(
    `/api/owner/shops/${restaurantId}/is_connected_to_delivery_service`,
    {},
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    }
  )

  if (status === 200) {
    return restaurantId
  } else {
    const errorText = 'Не удалось изменить статус ресторана'
    message.error(errorText)
    throw new Error(errorText)
  }
})

setAvgDeliveryTimeFx.use(async ({ restaurantId, avg_delivery_time }) => {
  await axios.put(
    `/api/owner/shops/${restaurantId}/avg_delivery_time`,
    { avg_delivery_time },
    {
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    }
  )
  return { id: restaurantId, avg_delivery_time }
})

setAvgPreparationTimeFx.use(
  async ({ restaurantId, approximate_order_preparation_time }) => {
    await axios.put(
      `/api/owner/shops/${restaurantId}/approximate_order_preparation_time`,
      { approximate_order_preparation_time },
      {
        headers: {
          Authorization: `Bearer ${$token.getState()}`,
        },
      }
    )
    return { id: restaurantId, approximate_order_preparation_time }
  }
)

$restaurants
  .on(loadReastaurantsFx.done, (_, { result }) => result)
  .on(updateSpecialGiftFx.done, (restaurants, { result }) =>
    restaurants.map((restaurant) =>
      restaurant.id === result.id ? result : restaurant
    )
  )
  .on(setAvailablityFx.done, (restaurants, { result }) =>
    restaurants.map((restaurant) =>
      restaurant.id === result
        ? { ...restaurant, available: !restaurant.available }
        : restaurant
    )
  )
  .on(setDeliveryServiceStatusFx.done, (restaurants, { result }) =>
    restaurants.map((restaurant) =>
      restaurant.id === result
        ? {
            ...restaurant,
            is_connected_to_delivery_service: !Boolean(
              restaurant.is_connected_to_delivery_service
            ),
          }
        : restaurant
    )
  )
  .on(setAvgDeliveryTimeFx.done, (restaurants, { result }) =>
    restaurants.map((restaurant) =>
      restaurant.id === result.id
        ? { ...restaurant, avg_delivery_time: result.avg_delivery_time }
        : restaurant
    )
  )
  .on(setAvgPreparationTimeFx.done, (restaurants, { result }) =>
    restaurants.map((restaurant) =>
      restaurant.id === result.id
        ? {
            ...restaurant,
            approximate_order_preparation_time:
              result.approximate_order_preparation_time,
          }
        : restaurant
    )
  )
  .reset(userLoggedOut)

$restaurants.watch(console.log)
$restaurants.updates.watch(setFromLocalStorage)

sample({
  source: $restaurants,
  clock: restaurantSelected,
  fn: (restaurants, selectedId) => {
    if (restaurants.length > 0) {
      const foundRestaurant = restaurants.find(({ id }) => id === selectedId)
      if (foundRestaurant) {
        return foundRestaurant
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  },
  target: $selectedRestaurant,
})

$selectedRestaurant.reset(userLoggedOut)

sample({
  source: $selectedRestaurant,
  clock: setAvailablityFx.done,
  fn: (restaurant, { result }) => {
    if (restaurant.id === result) {
      return { ...restaurant, available: !restaurant.available }
    }
    return restaurant
  },
  target: $selectedRestaurant,
})

$selectedRestaurant.updates.watch((restaurant) => {
  if (restaurant) {
    const { id } = restaurant
    localStorage.setItem(selectedRestaurantKey, id)
    loadDishesFx(id)
    loadDishCategoriesFx(id)
    loadDishOptionsFx(id)
    loadOptionGroupsFx(id)
  }
})

userLoggedOut.watch(() => {
  localStorage.removeItem(selectedRestaurantKey)
})

function setFromLocalStorage() {
  try {
    const restaurantId = JSON.parse(localStorage.getItem(selectedRestaurantKey))
    if (restaurantId) {
      restaurantSelected(restaurantId)
    }
  } catch (e) {
    console.log(e)
  }
}
