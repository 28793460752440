import axios from 'axios'
import { $feedbacks, $score, loadRatingStatsFx } from '.'
import { $token } from '../user'

function getScoreStats(score, feedbacks) {
  const reals = feedbacks.filter(item => !item.auto && item.score === score)
  const autos = feedbacks.filter(item => item.auto && item.score === score)

  return [
    {
      score: `Оценка ${score}`,
      type: 'Пользовательские отзывы',
      count: reals.length,
    },
    {
      score: `Оценка ${score}`,
      type: 'Автоматические отзывы',
      count: autos.length,
    },
  ]
}

loadRatingStatsFx.use(async ({ shopId }) => {
  const { data } = await axios.get(`/api/owner/shops/${shopId}/score`, {
    headers: { Authorization: `Bearer ${$token.getState()}` },
  })
  if (data.code === 'ok') {
    return data.data
  } else {
    throw new Error('Не удалось загрузить информацию о рейтинге ресторана')
  }
})

$score.on(loadRatingStatsFx.done, (_, { result }) => result.score.rating_score)
$feedbacks.on(loadRatingStatsFx.done, (_, { result: { feedbacks } }) => {
  return [
    ...getScoreStats(5, feedbacks),
    ...getScoreStats(4, feedbacks),
    ...getScoreStats(3, feedbacks),
    ...getScoreStats(2, feedbacks),
    ...getScoreStats(1, feedbacks),
  ]
})
