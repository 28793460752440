import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { useLocation, useHistory } from 'react-router-dom'
import Text from 'antd/lib/typography/Text'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import Space from 'antd/lib/space'
import Tabs from 'antd/lib/tabs'
import Descriptions from 'antd/lib/descriptions'
import Modal from 'antd/lib/modal/Modal'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'
import ReportsTable from './Reports/Table'
import PageHeader from '../../components/PageHeader'
import TransactionsTable from '../../components/TransactionsTable'
import ScreenLayout from '../../components/ScreenLayout'
import formLayout, { tailLayout } from '../../params/formLayout'
import {
  $balance,
  $transactions,
  loadTransactionsFx,
} from '../../models/transactions'
import { $reports, loadReportsFx } from '../../models/reports'
import {
  $billingModal,
  billingModalClosed,
  billingModalOpened,
  billingSubmitted,
} from '../../models/billing'
import '../../third-party/widgetStyles.css'

const { TabPane } = Tabs

const Reports = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()
  const transactions = useStore($transactions)
  const balance = useStore($balance)
  const reports = useStore($reports)
  const billingModal = useStore($billingModal)

  useEffect(() => {
    if (!transactions) {
      loadTransactionsFx()
    }
  }, [transactions])

  useEffect(() => {
    if (!reports) {
      loadReportsFx()
    }
  }, [reports])

  return (
    <ScreenLayout>
      <PageHeader title="Отчёты" />
      <Space direction="vertical">
        <Descriptions>
          <Descriptions.Item label="Текущий баланс">
            {transactions && (
              <Text strong type={Number(balance) < 0 ? 'danger' : 'success'}>
                {balance} руб.
              </Text>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Button type="primary" onClick={() => billingModalOpened()}>
          Пополнить
        </Button>
      </Space>
      <Divider />
      <Tabs defaultActiveKey={pathname} onTabClick={key => history.push(key)}>
        <TabPane tab="Отчёты" key="/reports">
          <ReportsTable dataSource={reports} />
        </TabPane>
        <TabPane tab="История транзакций" key="/reports/history">
          <TransactionsTable dataSource={transactions} />
        </TabPane>
      </Tabs>

      <Modal
        title="Пополнение баланса"
        visible={billingModal}
        onCancel={() => {
          billingModalClosed()
          form.resetFields()
        }}
        footer={false}
        width={480}
      >
        <Form
          form={form}
          component={false}
          onFinish={values => {
            billingSubmitted(values)
            form.resetFields()
          }}
        >
          <Form.Item label="Сумма" name="amount" {...formLayout}>
            <InputNumber
              min={1}
              style={{ width: '100%' }}
              placeholder="Введите сумму пополнения в ₽"
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button
                onClick={() => {
                  billingModalClosed()
                  form.resetFields()
                }}
              >
                Отмена
              </Button>
              <Button type="primary" onClick={() => form.submit()}>
                Подтвердить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </ScreenLayout>
  )
}

export default Reports
