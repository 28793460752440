import Axios from 'axios'
import moment from 'moment'
import message from 'antd/lib/message'
import { $transactions, $balance, loadTransactionsFx } from '.'
import { $token } from '../user'

loadTransactionsFx.use(async () => {
  const token = $token.getState()

  const { data } = await Axios.get('/api/owner/transactions', {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (data.code === 'error') {
    message.error(data.message)
    throw new Error(data.message)
  } else {
    return data.data
  }
})

/**
 * @returns String
 * @param {String} amount
 */
function addHundredth(amount) {
  const cents = amount.split('.')[1]
  return cents.length < 2 ? amount + '0' : amount
}

$balance.on(loadTransactionsFx.done, (_, { result: { balance } }) =>
  addHundredth(balance),
)

$transactions.on(loadTransactionsFx.done, (_, { result }) => {
  return result.transactions.sort(
    (a, b) => moment(b.createdAt) - moment(a.createdAt),
  )
})
