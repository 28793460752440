import axios from 'axios'
import fileDownload from 'js-file-download'
import { $reports, loadReportsFx, downloadReportFx } from '.'
import { $token } from '../user'

loadReportsFx.use(async () => {
  const token = $token.getState()
  const { data } = await axios.get(`/api/owner/reports/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return data
})

downloadReportFx.use(async ({ reportId, filename }) => {
  const mime = 'application/vnd.ms-excel; charset=utf-8'

  await axios
    .get(`/api/owner/reports/${reportId}/file`, {
      'Content-Type': mime,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${$token.getState()}`,
      },
    })
    .then(res => {
      fileDownload(res.data, filename, mime)
    })
})

$reports.on(loadReportsFx.done, (_, { result }) => {
  if (result.code === 'ok') {
    return result.data
  }
})
