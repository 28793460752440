import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'

const Descriptions = ({ title, children }) => {
  return (
    <Grid>
      <Title level={4}>{title}</Title>
      {children}
    </Grid>
  )
}

const Item = ({ label, children }) => {
  return (
    <DescriptionsItem>
      <Text type="secondary" style={{ fontSize: '0.9em' }}>
        {label}
      </Text>
      <Text>{children || <Text type="secondary">&mdash;</Text>}</Text>
    </DescriptionsItem>
  )
}

const LegalInfo = ({ restaurant }) => {
  if (!restaurant) return null

  const {
    title,
    address,
    contact,
    contact_email,
    legal_name,
    legal_address,
    mailing_address,
    accounting_phone,
    foundation_document_type,
    foundation_document_info,
    contract_number,
    contract_start_date,
    signatory_name,
    signatory_position,
    inn,
    kpp,
    bik,
    ogrn,
    checking_account,
    commission,
    account: { contact: ownerContact, email: ownerEmail },
  } = restaurant

  return (
    <div>
      <Descriptions title="Общая информация">
        <Item label="Название">
          <Text>{title}</Text>
        </Item>
        <Item label="Адрес">
          <Text>{address}</Text>
        </Item>
        <Item label="Телефон ресторана">{contact}</Item>
        <Item label="Телефон директора">{ownerContact}</Item>
        <Item label="Эл. почта">{contact_email}</Item>
        <Item label="Эл. почта управляющего">{ownerEmail}</Item>
      </Descriptions>

      <Descriptions title="Бухгалтерская информация">
        <Item label="ИНН">{inn}</Item>
        <Item label="КПП">{kpp}</Item>
        <Item label="ОГРН">{ogrn}</Item>
        <Item label="Расчётный счёт">{checking_account}</Item>
        <Item label="БИК">{bik}</Item>
        <Item label="Юридическое название">{legal_name}</Item>
        <Item label="Юридический адрес">{legal_address}</Item>
        <Item label="Почтовый адрес">{mailing_address}</Item>
        <Item label="Телефон бухгатерии">{accounting_phone}</Item>
        <Item label="Номер договора">{contract_number}</Item>
        <Item label="Дата начала действия договора">
          {moment(contract_start_date).format('DD MMMM YYYY')}
        </Item>
        <Item label="Имя подписанта">{signatory_name}</Item>
        <Item label="Должность подписанта">{signatory_position}</Item>
        <Item label="Тип документа основания">{foundation_document_type}</Item>
        <Item label="Сведения о документе основании">
          {foundation_document_info}
        </Item>
      </Descriptions>

      <Descriptions title="Комиссия">
        <Item label="Комиссия сервису">
          <Text>{commission}%</Text>
        </Item>
      </Descriptions>
    </div>
  )
}

export default LegalInfo

const Grid = styled.div`
  display: grid;
  gap: 1.25em;
  margin-bottom: 1.5em;
`
const DescriptionsItem = styled.div`
  display: grid;
`
