import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import 'moment/locale/ru'
import ConfigProvider from 'antd/lib/config-provider'
import locale from 'antd/es/locale/ru_RU'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import RestaurantsPage from './pages/Restaurants'
import PrivateRoute from './components/PrivateRoute'
import history from './utils/history'
import 'antd/dist/antd.css'
import './App.css'

function App() {
  return (
    <ConfigProvider locale={locale}>
      <div className="App">
        <Router history={history}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/restaurants">
              <RestaurantsPage />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </div>
    </ConfigProvider>
  )
}

export default App
