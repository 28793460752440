import React, { useEffect } from 'react'
import Text from 'antd/lib/typography/Text'
import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import { $blacklist, loadBlackListFx } from '../../models/blacklist'
import BlackListTable from './BlackList/Table'
import { useStore } from 'effector-react'
import BlackListForm from './BlackList/Form'

const BlackList = ({ restaurant }) => {
  const blacklist = useStore($blacklist)

  useEffect(() => {
    if (restaurant) {
      loadBlackListFx(restaurant.id)
    }
  }, [restaurant])

  return restaurant ? (
    <ScreenLayout>
      <PageHeader
        title={<Text>Чёрный список клиентов: {restaurant.title}</Text>}
      />
      <BlackListForm restaurantId={restaurant.id} />

      <BlackListTable restaurantId={restaurant.id} data={blacklist} />
    </ScreenLayout>
  ) : null
}

export default BlackList
