import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from 'effector-react'
import Tabs from 'antd/lib/tabs'
import Skeleton from 'antd/lib/skeleton'

import Stats from './RestaurantInfo/Stats'
import Rating from './RestaurantInfo/Rating'
import LegalInfo from './RestaurantInfo/LegalInfo'
import PageHeader from '../../components/PageHeader'
import ScreenLayout from '../../components/ScreenLayout'
import {
  $selectedRestaurant,
  loadReastaurantsFx,
} from '../../models/restaurants'

const { TabPane } = Tabs

const RestaurantInfo = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const selectedRestaurant = useStore($selectedRestaurant)
  const isInfoLiading = useStore(loadReastaurantsFx.pending)

  return selectedRestaurant ? (
    <ScreenLayout>
      <PageHeader title={selectedRestaurant.title} />
      <Tabs defaultActiveKey={pathname} onTabClick={key => history.push(key)}>
        <TabPane tab="Статистика" key="/info/stats">
          <Stats shopId={selectedRestaurant.id} />
        </TabPane>
        <TabPane tab="Рейтинг" key="/info/rating">
          <Rating
            shopId={selectedRestaurant.id}
            shouldRender={pathname === '/info/rating'}
          />
        </TabPane>
        <TabPane tab="Информация" key="/info/legal">
          {isInfoLiading ? (
            <Skeleton active />
          ) : (
            <LegalInfo restaurant={selectedRestaurant} />
          )}
        </TabPane>
      </Tabs>
    </ScreenLayout>
  ) : (
    <span>Загрузка...</span>
  )
}

export default RestaurantInfo
