import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import {
  Input,
  InputNumber,
  Form,
  Button,
  Select,
  Col,
  Row,
  Spin,
  Alert,
  Typography,
} from 'antd'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import { $selectedRestaurant } from '../../models/restaurants'
import { $dishes } from '../../models/dishes'
import { $specialGift, updateSpecialGiftFx } from '../../models/specials'

const Container = styled.div`
  max-width: 1024px;
`

const { Text } = Typography

function SpecialsScreen() {
  const selectedRestaurant = useStore($selectedRestaurant)
  const dishes = useStore($dishes)
  const specialGift = useStore($specialGift)
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(specialGift)
  }, [specialGift, form])

  async function onFinish() {
    const values = await form.validateFields()
    const result = Object.keys(values).reduce((acc, key) => {
      if (values[key].threshold && values[key].gifts) {
        acc[values[key].threshold] = values[key].gifts
      }
      return acc
    }, {})

    form.resetFields()
    updateSpecialGiftFx({ shopId: selectedRestaurant.id, update: result })
  }

  const levels = [
    { key: 1, label: 'Уровень 1' },
    { key: 2, label: 'Уровень 2' },
    { key: 3, label: 'Уровень 3' },
    { key: 4, label: 'Уровень 4' },
    { key: 5, label: 'Уровень 5' },
  ]

  return (
    <ScreenLayout>
      <PageHeader title="Акции" />
      <Container>
        <Alert
          type="info"
          style={{ marginBottom: 32 }}
          message={
            <Text>
              В левой колонке укажите минимальную сумму заказа, в правой
              выберите блюда (товары), которые будут бесплатно добавлены к
              заказу.
            </Text>
          }
        />
        <Form onFinish={onFinish} form={form} component={false}>
          <Spin spinning={!selectedRestaurant}>
            {levels.map(({ key, label }) => (
              <Form.Item key={label}>
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={6}>
                      <Form.Item name={[key, 'threshold']} noStyle>
                        <InputNumber
                          min={0}
                          style={{ width: '100%' }}
                          placeholder="Мин. сумма заказа"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Form.Item name={[key, 'gifts']} noStyle>
                        <Select
                          mode="multiple"
                          placeholder="Выберите товары"
                          style={{ width: '100%' }}
                        >
                          {dishes.map(dish => (
                            <Select.Option value={dish.id} key={dish.key}>
                              {dish.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            ))}
            <Form.Item>
              <Row gutter={8}>
                <Col span={4}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    onClick={onFinish}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Spin>
        </Form>
      </Container>
    </ScreenLayout>
  )
}

export default SpecialsScreen
