import Axios from 'axios'
import { $stats, loadStatsFx } from '.'
import { $token } from '../user'

loadStatsFx.use(async ({ shopId = 0, period = 'month', start, end }) => {
  const token = $token.getState()
  const queryParams = `?period=${period}${
    start ? `&customPeriodStart=${start}` : ''
  }${end ? `&customPeriodEnd=${end}` : ''}`

  const { data } = await Axios.get(
    `/api/owner/shops/${shopId}/stats${queryParams}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )

  return data
})

$stats.on(loadStatsFx.done, (_, { result }) => {
  if (result.code === 'ok') {
    return result.data
  }
})
