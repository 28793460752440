import React from 'react'
import moment from 'moment'
import Table from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'

const TransactionsTable = props => {
  const columns = [
    {
      title: 'Дата',
      render: (_, { createdAt }) => {
        const date = moment(createdAt).format('DD.MM.YYYY')
        const time = moment(createdAt).format('HH:mm')

        return (
          <Text>
            {date} <Text type="secondary">{time}</Text>
          </Text>
        )
      },
    },
    {
      title: 'Сумма',
      render: (_, { amount }) => {
        const _amount = Number(amount)

        return (
          <Text strong type={_amount < 0 ? 'danger' : 'success'}>
            {amount}
          </Text>
        )
      },
    },
    {
      title: 'Источник',
      render: (_, { admin, report, billing }) => {
        if (admin) {
          return <Text type="secondary">Корректировка</Text>
        }

        if (report) {
          return <Text type="secondary">Автоматический отчёт</Text>
        }

        if (billing) {
          return <Text type="secondary">Пополнение счёта</Text>
        }

        return 'Неизвестный источник'
      },
    },
  ]

  return (
    <Table
      {...props}
      rowKey="id"
      columns={columns}
      scroll={{ x: true }}
      bordered
    />
  )
}

export default TransactionsTable
