import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'

import App from './App'
import './models/init'

export const DEV = process.env.REACT_APP_ENV !== 'production'

DEV && console.log('DEVELOPMENT')

ReactDOM.render(
  <ConfigProvider locale={ruRU}>
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
)
