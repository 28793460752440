import { createStore, createEffect, createEvent } from 'effector'

export const selectedRestaurantKey = 'selected_restaurant'

export const loadReastaurantsFx = createEffect()
export const setAvailablityFx = createEffect()
export const setDeliveryServiceStatusFx = createEffect()
export const setAvgDeliveryTimeFx = createEffect()
export const setAvgPreparationTimeFx = createEffect()

export const restaurantSelected = createEvent('restaurant selected')

export const $restaurants = createStore([])
export const $selectedRestaurant = createStore(null)
