import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Button from 'antd/lib/button'
import {
  $restaurants,
  $selectedRestaurant,
  restaurantSelected,
} from '../models/restaurants'
import { loadReastaurantsFx } from '../models/restaurants'
import Spin from 'antd/lib/spin'
import { useHistory } from 'react-router'
import { userLoggedOut } from '../models/user'
import Divider from 'antd/lib/divider'

const RestaurantsPage = () => {
  const restaurants = useStore($restaurants)
  const loading = useStore(loadReastaurantsFx.pending)
  const selectedRestaurant = useStore($selectedRestaurant)
  const history = useHistory()

  useEffect(() => {
    if (selectedRestaurant) {
      history.goBack()
    }
  }, [history, selectedRestaurant])

  return (
    <Layout>
      {loading ? (
        <Spin spinning />
      ) : (
        <List>
          <h2 style={{ textAlign: 'center' }}>Выберите ресторан</h2>
          {restaurants.map(({ id, title }) => {
            const buttonText =
              title.length > 32 ? title.slice(0, 28).concat('...') : title

            return (
              <Button
                key={`${id}-${title}`}
                size="large"
                block
                onClick={() => restaurantSelected(id)}
              >
                {buttonText}
              </Button>
            )
          })}
          <Divider />
          <Button
            danger
            size="large"
            type="link
          "
            block
            onClick={() => userLoggedOut()}
          >
            Выход
          </Button>
        </List>
      )}
    </Layout>
  )
}

export default RestaurantsPage

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
`

const List = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`
