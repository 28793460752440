import React from 'react'
import { useStore } from 'effector-react'
import { useLocation, useHistory } from 'react-router-dom'
import { Tabs, Typography, Skeleton } from 'antd'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import Dishes from './MenuScreen/Dishes'
import DishCategories from './MenuScreen/DishCategories'
import DishOptions from './MenuScreen/DishOptions'
import DishOptionsGroups from './MenuScreen/DishOptionsGroups'
import { $selectedRestaurant } from '../../models/restaurants'
import { loadDishesFx, $dishes } from '../../models/dishes'
import {
  loadDishCategoriesFx,
  $dishCategories,
} from '../../models/dishCategories'
import { loadDishOptionsFx, $dishOptions } from '../../models/dishOptions'
import {
  loadOptionGroupsFx,
  $optionGroups,
} from '../../models/dishOptionGroups'

const { TabPane } = Tabs
const { Text } = Typography

/**
 * Панель управления блюдами и товарами ресторана
 */
function MenuScreen() {
  const selectedRestaurant = useStore($selectedRestaurant)
  const dishes = useStore($dishes)
  const dishesLoading = useStore(loadDishesFx.pending)
  const dishCategories = useStore($dishCategories)
  const dishCategoriesLoading = useStore(loadDishCategoriesFx.pending)
  const dishOptions = useStore($dishOptions)
  const dishOptionsLoading = useStore(loadDishOptionsFx.pending)
  const optionGroups = useStore($optionGroups)
  const optionGroupsLoading = useStore(loadOptionGroupsFx.pending)
  const { pathname } = useLocation()
  const history = useHistory()

  return (
    <ScreenLayout>
      <PageHeader
        title={
          <Text>
            Меню
            {selectedRestaurant && <Text>: {selectedRestaurant.title}</Text>}
          </Text>
        }
      />
      <Tabs defaultActiveKey={pathname} onTabClick={key => history.push(key)}>
        <TabPane tab="Блюда" key={'/menu'}>
          {dishesLoading && dishes.length === 0 ? (
            <Skeleton active />
          ) : (
            <Dishes />
          )}
        </TabPane>
        <TabPane tab="Категории блюд" key={'/menu/categories'}>
          {dishCategoriesLoading && dishCategories.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishCategories />
          )}
        </TabPane>
        <TabPane tab="Опции" key={'/menu/options'}>
          {dishOptionsLoading && dishOptions.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishOptions />
          )}
        </TabPane>
        <TabPane tab="Группы опций" key={'/menu/option-groups'}>
          {optionGroupsLoading && optionGroups.length === 0 ? (
            <Skeleton active />
          ) : (
            <DishOptionsGroups />
          )}
        </TabPane>
      </Tabs>
    </ScreenLayout>
  )
}

export default MenuScreen
