import React, { useState, Fragment } from 'react'
import Table from 'antd/lib/table'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'
import Text from 'antd/lib/typography/Text'
import OrdersTable from '../../../components/OrdersTable'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'
import {
  DownloadOutlined,
  EllipsisOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import { downloadReportFx } from '../../../models/reports'

const ReportsTable = ({ dataSource, ...props }) => {
  const [ordersModal, setOrdersModal] = useState(null)
  const [ordersModalTitle, setOrdersModalTitle] = useState(null)
  const [ordersModalData, setOrdersModalData] = useState(null)

  const columns = [
    {
      title: 'Период',
      dataIndex: 'title',
    },
    {
      title: 'Заказов',
      render: (_, { orders }) => {
        return orders.length
      },
    },
    {
      title: 'Баланс',
      render: (_, { amount }) => {
        return (
          <Text type={Number(amount) < 0 ? 'danger' : 'success'}>{amount}</Text>
        )
      },
    },
    {
      width: 'min-content',
      render: (_, { id, title, orders }) => {
        const filename = `${id}_${title}.xlsx`

        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  icon={<FileSearchOutlined />}
                  disabled={orders.length < 1}
                  onClick={() => {
                    setOrdersModalTitle(title)
                    setOrdersModalData(orders)
                    setOrdersModal(true)
                  }}
                >
                  Детализация
                </Menu.Item>
                <Menu.Item
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    downloadReportFx({
                      reportId: id,
                      filename,
                    })
                  }
                >
                  Скачать
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        )
      },
    },
  ]

  return (
    <Fragment>
      <Table
        {...props}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        bordered
        scroll={{ x: true }}
      />
      <Modal
        title={ordersModalTitle}
        width="100%"
        visible={ordersModal}
        onCancel={() => setOrdersModal(false)}
        destroyOnClose={true}
        footer={false}
      >
        <OrdersTable
          dataSource={ordersModalData}
          rowKey="id"
          bordered
          scroll={{ x: true }}
        />
      </Modal>
    </Fragment>
  )
}

export default ReportsTable
