import React, { useEffect } from 'react'
import { Space, Form, Input, Button } from 'antd'
import { useStore } from 'effector-react'
import { $blacklist, banCustomerFx } from '../../../models/blacklist'

const REGEXP = /^\d+$/

const BlackListForm = ({ restaurantId }) => {
  const [form] = Form.useForm()
  const blacklist = useStore($blacklist)

  useEffect(() => {
    form.resetFields()
  }, [blacklist, form])

  function handleSubmit(values) {
    const phone =
      values.phone[0] === '8' ? `7${values.phone.slice(1)}` : values.phone
    banCustomerFx({ restaurantId, customer: { phone } })
  }

  return (
    <Form form={form} component={false} onFinish={handleSubmit}>
      <Space align="baseline">
        <Form.Item
          name="phone"
          rules={[{ pattern: REGEXP, message: 'Некорректный номер' }]}
        >
          <Input placeholder="Введите номер телефона" />
        </Form.Item>
        <Button type="primary" onClick={() => form.submit()}>
          Добавить
        </Button>
      </Space>
    </Form>
  )
}

export default BlackListForm
