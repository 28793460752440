import React from 'react'
import styled from 'styled-components'
import { Layout, Form, Input, Button as AntButton, Typography } from 'antd'
import { useStore } from 'effector-react'
import { loginFx, $loginFailMessage } from '../../models/user'

const ContentWrapper = styled(Layout.Content)`
  padding: 64px 16px 96px;
  min-height: 100vh;
`

const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 480px;
  margin: 0 auto;
  padding: 32px;
`

const Title = styled.h1`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
`

const Button = styled(AntButton)`
  width: 100%;
`

const { Item } = Form
const { Text } = Typography

function LoginScreen() {
  const loading = useStore(loginFx.pending)
  const loginFailMessage = useStore($loginFailMessage)

  return (
    <Layout>
      <ContentWrapper>
        <Content>
          <Title>Авторизация</Title>
          <Form layout="vertical" onFinish={loginFx}>
            <Item name="email">
              <Input size="large" placeholder="Логин" disabled={loading} />
            </Item>
            <Item name="password">
              <Input.Password
                size="large"
                placeholder="Пароль"
                disabled={loading}
              />
            </Item>
            <Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loading}
              >
                Войти
              </Button>
            </Item>
            <Text type="danger">{loginFailMessage}</Text>
          </Form>
        </Content>
      </ContentWrapper>
    </Layout>
  )
}

export default LoginScreen
