import axios from 'axios'
import { loadReastaurantsFx } from '../restaurants'
import {
  tokenKey,
  localTokenSetted,
  userLoggedOut,
  $token,
  loginFx,
  $loginFailMessage,
  $userId,
} from './'

loginFx.use(async ({ email, password }) => {
  const res = await axios.post('/api/owner/login', {
    email: email.trim(),
    password,
  })
  return res.data.token
})

$token
  .on(localTokenSetted, (_, token) => token)
  .on(loginFx.done, (_, { result }) => result)
  .reset(userLoggedOut)

$token.updates.watch(token => {
  localStorage.setItem(tokenKey, JSON.stringify(token))
  loadReastaurantsFx(token)
})

$userId.on(loadReastaurantsFx.done, (_, { result }) => {
  if (result && result.length > 0) {
    return result[0].account_id
  }
})

$loginFailMessage.on(loginFx.fail, (_, { error }) => {
  console.log(error.response.status)
  console.log(error.message)
  return 'Произошла ошибка. Проверьте правильность введённых данных или попробуйте повторить позднее.'
})

try {
  const token = JSON.parse(localStorage.getItem(tokenKey))
  if (!token) {
    throw new Error('no local token')
  }
  localTokenSetted(token)
} catch (e) {
  console.log(e)
}
