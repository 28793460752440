import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore } from 'effector-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom'
import Layout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import {
  UnorderedListOutlined,
  NotificationOutlined,
  GiftOutlined,
  SettingOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  StopOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import OrdersScreen from './screens/OrdersScreen'
import MenuScreen from './screens/MenuScreen'
import SpecialsScreen from './screens/SpecialsScreen'
import SettingsScreen from './screens/SettingsScreen'
import {
  $restaurants,
  restaurantSelected,
  $selectedRestaurant,
  loadReastaurantsFx,
} from '../models/restaurants'
import { userLoggedOut } from '../models/user'
import Reports from './screens/Reports'
import RestaurantInfo from './screens/RestaurantInfo'
import BlackList from './screens/BlackList'
import { Spin } from 'antd'

const Sider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
`

const Content = styled(Layout.Content)`
  transition: margin-left 0.2s;
  transition-timing-function: ease;
`

const { SubMenu } = Menu

function Dashboard() {
  const [collapsed, setCollapsed] = useState(true)
  const history = useHistory()
  const restaurants = useStore($restaurants)
  const loading = useStore(loadReastaurantsFx.pending)
  const selectedRestaurant = useStore($selectedRestaurant)
  const { pathname } = useLocation()

  useEffect(() => {
    if (restaurants.length > 1 && !selectedRestaurant) {
      history.push('/restaurants')
    }
  }, [restaurants, selectedRestaurant, history])

  useEffect(() => {
    if (restaurants.length === 1) {
      restaurantSelected(restaurants[0].id)
    }
  }, [restaurants])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  function openLogoutModal() {
    Modal.confirm({
      title: 'Выход',
      icon: <ExclamationCircleOutlined />,
      content: 'Вы действительно хотите выйти из панели управления?',
      okButtonProps: {
        danger: true,
      },
      okText: 'Выйти',
      cancelText: 'Отмена',
      onOk() {
        userLoggedOut()
      },
    })
  }

  return loading ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        minHeight: '100vh',
        padding: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin spinning />
    </div>
  ) : (
    <Router>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
          {restaurants && restaurants.length > 1 && (
            <Menu mode="vertical" theme="dark" selectable={false}>
              <SubMenu
                key="sub1"
                title={
                  selectedRestaurant ? selectedRestaurant.title : 'Загрузка…'
                }
                icon={<ShopOutlined />}
              >
                {restaurants.map(({ id, title }) => {
                  return (
                    <Menu.Item
                      key={`restaurant-${id}`}
                      onClick={() => restaurantSelected(id)}
                    >
                      {title}
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            </Menu>
          )}
          <Menu
            defaultSelectedKeys={pathname === '/' ? '/info' : [pathname]}
            theme="dark"
            mode="vertical"
          >
            <Menu.Item key="/info" icon={<InfoCircleOutlined />}>
              <Link to="/info">Информация</Link>
            </Menu.Item>
            <Menu.Item key="/orders" icon={<NotificationOutlined />}>
              <Link to="/orders">Заказы</Link>
            </Menu.Item>
            <Menu.Item key="/menu" icon={<UnorderedListOutlined />}>
              <Link to="/menu">Меню</Link>
            </Menu.Item>
            <Menu.Item key="/specials" icon={<GiftOutlined />}>
              <Link to="/specials">Акции</Link>
            </Menu.Item>
            <Menu.Item key="/reports" icon={<DollarOutlined />}>
              <Link to="/reports">Отчёты</Link>
            </Menu.Item>
            <Menu.Item key="/blacklist" icon={<StopOutlined />}>
              <Link to="/blacklist">Чёрный список</Link>
            </Menu.Item>
            <Menu.Item key="/settings" icon={<SettingOutlined />}>
              <Link to="/settings">Настройки</Link>
            </Menu.Item>
          </Menu>
          <Menu theme="dark" mode="vertical" selectable={false}>
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              danger
              onClick={openLogoutModal}
            >
              Выход
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ marginLeft: collapsed ? 80 : 200 }}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/info" />
              </Route>
              <Route path="/info">
                <RestaurantInfo />
              </Route>
              <Route path="/orders">
                <OrdersScreen />
              </Route>
              <Route path="/menu">
                <MenuScreen />
              </Route>
              <Route path="/specials">
                <SpecialsScreen />
              </Route>
              <Route path="/reports">
                <Reports />
              </Route>
              <Route path="/blacklist">
                <BlackList restaurant={selectedRestaurant} />
              </Route>
              <Route path="/settings">
                <SettingsScreen />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Router>
  )
}

export default Dashboard
