import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import {
  $feedbacks,
  $score,
  loadRatingStatsFx,
} from '../../../models/ratingStats'
import Statistic from 'antd/lib/statistic'
import Skeleton from 'antd/lib/skeleton'
import StarOutlined from '@ant-design/icons/StarOutlined'
import Divider from 'antd/lib/divider'
import { Bar } from '@ant-design/charts'

const Rating = ({ shopId, shouldRender }) => {
  const score = useStore($score)
  const feedbacks = useStore($feedbacks)
  const loading = useStore(loadRatingStatsFx.pending)

  useEffect(() => {
    if (shopId) {
      loadRatingStatsFx({ shopId })
    }
  }, [shopId])

  return shouldRender ? (
    <div>
      <Statistic
        title="Рейтинг на основе пользовательских оценок за последние 3 месяца"
        loading={loading}
        prefix={<StarOutlined />}
        value={score || 'Мало оценок'}
        precision={1}
      />
      <Divider />
      <Bar
        data={feedbacks}
        isStack={true}
        xField="count"
        yField="score"
        seriesField="type"
      />
    </div>
  ) : (
    <Skeleton loading />
  )
}

export default Rating
