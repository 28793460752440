import React, { useState } from 'react'
import { useStore } from 'effector-react'
import styled from 'styled-components'
import {
  Space,
  Row as AntRow,
  Col,
  Form,
  InputNumber,
  Switch,
  Button,
  Skeleton,
  Typography,
  Divider,
} from 'antd'

import ScreenLayout from '../../components/ScreenLayout'
import PageHeader from '../../components/PageHeader'
import {
  $selectedRestaurant,
  setAvailablityFx,
  setAvgDeliveryTimeFx,
  setDeliveryServiceStatusFx,
  setAvgPreparationTimeFx,
} from '../../models/restaurants'

const Row = styled(AntRow)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const FormItem = styled(Form.Item)`
  margin: 0;
`

const { Text } = Typography

function SettingsScreen() {
  const [deliveryTimeEdit, setDeliveryTimeEdit] = useState(false)
  const [preparationTimeEdit, setPreparationTimeEdit] = useState(false)
  const selectedRestaurant = useStore($selectedRestaurant)
  const availablityPending = useStore(setAvailablityFx.pending)
  const deliveryServiceStatusPending = useStore(
    setDeliveryServiceStatusFx.pending
  )
  const [deliveryTimeForm] = Form.useForm()
  const [preparationTimeForm] = Form.useForm()

  async function onDeliveryTimeSubmit() {
    const { avg_delivery_time } = await deliveryTimeForm.validateFields()
    setAvgDeliveryTimeFx({
      restaurantId: selectedRestaurant.id,
      avg_delivery_time,
    })
    setDeliveryTimeEdit(false)
  }

  function onDeliveryTimeCancel() {
    deliveryTimeForm.resetFields()
    setDeliveryTimeEdit(false)
  }

  async function onPreparationTimeSubmit() {
    const { approximate_order_preparation_time } =
      await preparationTimeForm.validateFields()
    setAvgPreparationTimeFx({
      restaurantId: selectedRestaurant.id,
      approximate_order_preparation_time,
    })
    setPreparationTimeEdit(false)
  }

  function onPreparationTimeCancel() {
    preparationTimeForm.resetFields()
    setPreparationTimeEdit(false)
  }

  return (
    <ScreenLayout>
      <PageHeader title="Настройки" />
      {!selectedRestaurant ? (
        <Skeleton active />
      ) : (
        <>
          <Row gutter={8} align="middle">
            <Col>
              <Text>Ресторан работает:</Text>
            </Col>
            <Col>
              <Switch
                checked={selectedRestaurant.available}
                disabled={!selectedRestaurant}
                loading={availablityPending}
                onClick={() => setAvailablityFx(selectedRestaurant.id)}
              />
            </Col>
          </Row>
          <Row>
            <Text>Среднее время доставки (мин):</Text>
          </Row>
          <Row>
            <Form
              form={deliveryTimeForm}
              component={false}
              initialValues={selectedRestaurant}
            >
              <Space>
                <FormItem name="avg_delivery_time">
                  <InputNumber min={0} readOnly={!deliveryTimeEdit} />
                </FormItem>
                {!deliveryTimeEdit ? (
                  <Button
                    type="primary"
                    onClick={() => setDeliveryTimeEdit(true)}
                  >
                    Изменить
                  </Button>
                ) : (
                  <Space>
                    <Button
                      type="primary"
                      disabled={!selectedRestaurant}
                      onClick={onDeliveryTimeSubmit}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={onDeliveryTimeCancel}>Отмена</Button>
                  </Space>
                )}
              </Space>
            </Form>
          </Row>
          <Divider />
          <Row gutter={8} align="middle">
            <Col>
              <Text>Служба доставки:</Text>
            </Col>
            <Col>
              <Switch
                checked={selectedRestaurant.is_connected_to_delivery_service}
                disabled={true}
                loading={deliveryServiceStatusPending}
                onClick={() =>
                  setDeliveryServiceStatusFx(selectedRestaurant.id)
                }
              />
            </Col>
          </Row>
          <Row>
            <Text>Среднее время готовки (мин):</Text>
          </Row>
          <Row>
            <Form
              form={preparationTimeForm}
              component={false}
              initialValues={selectedRestaurant}
            >
              <Space>
                <FormItem name="approximate_order_preparation_time">
                  <InputNumber min={0} readOnly={!preparationTimeEdit} />
                </FormItem>
                {!preparationTimeEdit ? (
                  <Button
                    type="primary"
                    onClick={() => setPreparationTimeEdit(true)}
                  >
                    Изменить
                  </Button>
                ) : (
                  <Space>
                    <Button
                      type="primary"
                      disabled={!selectedRestaurant}
                      onClick={onPreparationTimeSubmit}
                    >
                      Сохранить
                    </Button>
                    <Button onClick={onPreparationTimeCancel}>Отмена</Button>
                  </Space>
                )}
              </Space>
            </Form>
          </Row>
        </>
      )}
    </ScreenLayout>
  )
}

export default SettingsScreen
