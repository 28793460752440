import { sample } from 'effector'
import axios from 'axios'
import { $token } from '../user'
import {
  loadDishesFx,
  createDishFx,
  updateDishFx,
  deleteDishFx,
  setAllInStockFx,
  $rawDishesData,
  $dishes,
  $dishActionPending,
} from './'

loadDishesFx.use(async shopId => {
  const res = await axios.get(`/api/owner/shops/${shopId}/`, {
    headers: {
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

createDishFx.use(async ({ shopId, values }) => {
  const res = await axios.post(
    `/api/owner/shops/${shopId}/items/`,
    { ...values },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

updateDishFx.use(async ({ shopId, itemId, values }) => {
  const res = await axios.put(
    `/api/owner/shops/${shopId}/items/${itemId}`,
    { ...values },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

deleteDishFx.use(async ({ shopId, itemId }) => {
  const res = await axios.delete(`/api/owner/shops/${shopId}/items/${itemId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${$token.getState()}`,
    },
  })
  return res.data.data
})

setAllInStockFx.use(async restaurantId => {
  const res = await axios.put(
    `/api/owner/shops/${restaurantId}/out_of_stock`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data.data
})

$rawDishesData
  .on(loadDishesFx.done, (_, { result }) => result)
  .on(createDishFx.done, (dishes, { result }) => [result, ...dishes])
  .on(updateDishFx.done, (dishes, { result }) =>
    dishes.map(dish => (dish.id === result.id ? { ...dish, ...result } : dish)),
  )
  .on(deleteDishFx.done, (dishes, { result }) =>
    dishes.filter(({ id }) => id !== result.id),
  )
  .on(setAllInStockFx.done, dishes =>
    dishes.map(dish =>
      dish.available ? { ...dish, out_of_stock: false } : dish,
    ),
  )

sample({
  source: $rawDishesData,
  fn: dishes =>
    dishes.map(dish => ({
      ...dish,
      groups: dish.groups || [],
      key: `dish-${dish.id}`,
    })),
  target: $dishes,
})

$dishActionPending
  .on(createDishFx.pending, (_, status) => status)
  .on(updateDishFx.pending, (_, status) => status)
  .on(deleteDishFx.pending, (_, status) => status)
  .on(setAllInStockFx.pending, (_, status) => status)
