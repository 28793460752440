import message from 'antd/lib/message'
import {
  billingSubmitted,
  $billingModal,
  billingModalOpened,
  billingModalClosed,
} from '.'
import { loadTransactionsFx } from '../transactions'
import { $userId } from '../user'
import PaytureWidget from '../../third-party/payture-widget.min.js'
import { DEV } from '../../utils/constants'

const PAYTURE_KEY = DEV ? 'MerchantEdaElista' : 'EdaElistaOpen3DS'
const PAYTURE_DOMAIN = DEV ? '2' : '1'

$billingModal
  .on(billingModalOpened, () => true)
  .on(billingModalClosed, () => false)

billingSubmitted.watch(({ amount }) => {
  const userId = $userId.getState()

  if (amount && typeof amount === 'number' && amount > 0 && userId) {
    try {
      new PaytureWidget({
        Key: PAYTURE_KEY,
        Domain: PAYTURE_DOMAIN,
        Amount: amount,
        Product: 'EdaElista.ru',
        CustomParams: {
          TemplateTag: 'Widget',
          BillingAccount: userId,
        },
        OnTransactionCompleted: function (success) {
          if (success) {
            billingModalClosed()
            message.success('Платёж принят')
            loadTransactionsFx()
          }
        },
      })
    } catch (e) {
      message.error(e)
    }
  } else {
    message.error('Ошибка запроса')
  }
})
