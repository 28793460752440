import axios from 'axios'
import { $token } from '../user'
import { sample } from 'effector'
import { $selectedRestaurant } from '../restaurants'
import { $specialGift, updateSpecialGiftFx } from './'

updateSpecialGiftFx.use(async ({ shopId, update }) => {
  const res = await axios.post(
    `/api/owner/shops/${shopId}/gifts`,
    { ...update },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${$token.getState()}`,
      },
    },
  )
  return res.data
})

sample({
  source: $selectedRestaurant,
  fn: restaurant => {
    if (restaurant) {
      const { specialGift } = restaurant

      if (specialGift) {
        return Object.keys(specialGift).reduce((acc, key, index) => {
          acc[index + 1] = { threshold: key, gifts: specialGift[key] }
          return acc
        }, {})
      }
      return {}
    }
  },
  target: $specialGift,
})
