import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useStore } from 'effector-react'
import { $stats, loadStatsFx } from '../../../models/stats'
import Skeleton from 'antd/lib/skeleton'
import Statistic from 'antd/lib/statistic'
import Divider from 'antd/lib/divider'
import Radio from 'antd/lib/radio'
import Spin from 'antd/lib/spin'
import Space from 'antd/lib/space'
import DatePicker from 'antd/lib/date-picker'
import Button from 'antd/lib/button'

const { RangePicker } = DatePicker

const Stats = ({ shopId }) => {
  const data = useStore($stats)
  const isLoading = useStore(loadStatsFx.pending)
  const [period, setPeriod] = useState('month')
  const [start, setStart] = useState(undefined)
  const [end, setEnd] = useState(undefined)

  useEffect(() => {
    if (period !== 'custom') {
      loadStatsFx({ shopId, period })
    } else if (start && end) {
      loadStatsFx({ shopId, start, end })
    }
  }, [shopId, period, start, end])

  return isLoading || !data ? (
    <Skeleton active />
  ) : (
    <Container>
      <Space>
        <Radio.Group
          onChange={({ target }) => setPeriod(target.value)}
          value={period}
        >
          <Radio.Button value="day">За день</Radio.Button>
          <Radio.Button value="week">За неделю</Radio.Button>
          <Radio.Button value="month">За месяц</Radio.Button>
          <Radio.Button value="year">За год</Radio.Button>
          <Radio.Button value="custom">Указать</Radio.Button>
        </Radio.Group>
        {period === 'custom' && (
          <Space>
            <RangePicker
              value={start && end ? [moment(start), moment(end)] : undefined}
              format="DD.MM.YYYY"
              onChange={range => {
                const [start, end] = range.map(date =>
                  date.format('YYYY-MM-DD'),
                )
                setStart(start)
                setEnd(end)
              }}
            />
            <Button
              type="primary"
              disabled={!start && !end}
              onClick={() => loadStatsFx({ shopId, start, end })}
            >
              Показать
            </Button>
          </Space>
        )}
      </Space>
      <Divider />
      <Spin spinning={isLoading}>
        <Grid>
          <Statistic
            title="Заказов выполнено"
            groupSeparator=" "
            valueStyle={{ color: 'green' }}
            value={data.successOrdersCount}
          />
          <Statistic
            title="Средний чек"
            valueStyle={{ color: 'green' }}
            groupSeparator=" "
            value={data.successOrdersAverageCheck}
            suffix="руб."
          />
          <Statistic
            title="Оборот"
            valueStyle={{ color: 'green' }}
            groupSeparator=" "
            value={data.successOrdersTurnOver}
            suffix="руб."
          />
          <Statistic
            title="Заказов отменено"
            valueStyle={{ color: 'red' }}
            groupSeparator=" "
            value={data.failureOrdersPercent}
            suffix="%"
          />
        </Grid>
      </Spin>
    </Container>
  )
}

export default Stats

const Container = styled.div`
  display: block;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  row-gap: 32px;
  max-width: 1024px;
`
