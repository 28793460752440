import React, { useState } from 'react'
import { useStore } from 'effector-react'
import {
  Table,
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Modal,
  Tag,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { $selectedRestaurant } from '../../../models/restaurants'
import {
  $optionGroups,
  updateOptionGroupFx,
  createOptionGroupFx,
} from '../../../models/dishOptionGroups'
import formLayout from '../../../params/formLayout'

function DishOptionsGroups() {
  const [editModal, setEditModal] = useState(false)
  const [editingKey, setEditingKey] = useState(0)
  const selectedRestaurant = useStore($selectedRestaurant)
  const optionGroups = useStore($optionGroups)
  const [form] = Form.useForm()

  function onAddClick() {
    form.resetFields()
    setEditModal(true)
    setEditingKey(0)
  }

  function onEditClick(record) {
    form.setFieldsValue(record)
    setEditingKey(record.id)
    setEditModal(true)
  }

  async function onEditSaveClick() {
    const values = await form.validateFields()
    const shopId = selectedRestaurant.id

    try {
      if (editingKey > 0) {
        updateOptionGroupFx({
          shopId,
          groupId: editingKey,
          group: values,
        })
      } else {
        createOptionGroupFx({
          shopId,
          group: values,
        })
      }
      setEditModal(false)
    } catch (e) {
      console.log('Validate Failed:', e)
      return e
    }
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      name: 'title',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      name: 'description',
    },
    {
      title: 'Позиция',
      dataIndex: 'position',
      name: 'position',
    },
    {
      title: 'Тип',
      name: 'type',
      render: (_, record) => {
        return <Tag>{record.type === 'radio' ? 'Радио-кнопка' : 'Чекбокс'}</Tag>
      },
    },
    {
      title: 'Действия',
      name: 'actions',
      width: '10%',
      render: (_, record) => {
        return (
          <Button type="link" onClick={() => onEditClick(record)}>
            Изменить
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{ marginBottom: 16 }}
        onClick={onAddClick}
      >
        Добавить группу
      </Button>
      <Table
        bordered
        dataSource={optionGroups}
        columns={columns}
        scroll={{ x: true }}
        pagination={{ showSizeChanger: true }}
      />
      <Modal
        visible={editModal}
        title={editingKey > 0 ? 'Редактирование группы' : 'Добавление группы'}
        centered
        width={640}
        okText="Сохранить"
        onOk={onEditSaveClick}
        onCancel={() => setEditModal(false)}
      >
        <Form {...formLayout} form={form} component={false}>
          <Form.Item
            label="Название"
            name="title"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>
          <Form.Item
            label="Описание"
            name="description"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Input placeholder="Введите описание" />
          </Form.Item>
          <Form.Item label="Позиция" name="position">
            <InputNumber
              placeholder="Укажите позицию"
              min={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="Тип"
            name="type"
            rules={[{ required: true, message: 'Обязательное поле!' }]}
          >
            <Radio.Group>
              <Radio.Button value="radio">Радио-кнопка</Radio.Button>
              <Radio.Button value="checkbox">Чекбокс</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default DishOptionsGroups
