/* eslint-disable */
function PaytureWidget(e) {
  var a = 'https://merchantgateway.payture.com/?',
    n = {
      Domain: '1',
      Key: '',
      Amount: 0,
      Product: '',
      CustomParams: void 0,
      ChequeParams: void 0,
    }
  this.id = 'widget-' + (Date.now() % 1e3)
  var d,
    r,
    o,
    s,
    c,
    u = this,
    t = 1e4,
    l = !1,
    i = !1
  function p() {
    d && ((d.style.display = 'none'), (d.style.opacity = 0)),
      r && (r.style.visibility = 'hidden'),
      s && (s.style.visibility = 'visible'),
      c && (c.style.visibility = 'hidden')
    var e = document.getElementById('viewport-widget-device-width')
    e && document.head.removeChild(e)
  }
  function m(e) {
    if (
      'CLOSE_PAYTURE_WIDGET_SUCCESS' == e.data ||
      'CLOSE_PAYTURE_WIDGET_ERROR' == e.data
    ) {
      i = !0
      var t = void 0
      switch (e.data) {
        case 'CLOSE_PAYTURE_WIDGET_SUCCESS':
          t = !0
          break
        case 'CLOSE_PAYTURE_WIDGET_ERROR':
          t = !1
          break
        default:
          console.warn(
            'Unknown message type from payment template registered: ',
            e.data,
          )
      }
      p(),
        'function' == typeof u.OnTransactionCompleted &&
          u.OnTransactionCompleted(t),
        m.detached || PaytureWidget.EventDispatcher.detachListener(m),
        h()
    }
  }
  function h() {
    if (d)
      try {
        document.body.removeChild(d)
      } catch (e) {}
  }
  ;(this.OnTransactionCompleted = function (e) {
    console.log('Transaction completed with Success=' + e)
  }),
    (l = navigator.userAgent.match(
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i,
    )),
    (function () {
      ;(d = document.createElement('div')).setAttribute(
        'id',
        'payture-widget-wrapper',
      )
      var e = document.createElement('div')
      e.setAttribute('id', 'payture-widget-shadow')
      var t = document.createElement('div')
      t.setAttribute('id', 'payture-widget-layout'),
        d.appendChild(e),
        d.appendChild(t),
        (s = document.createElement('div')).setAttribute(
          'id',
          'payture-widget-loader',
        )
      var i = document.createElement('div')
      if (
        (i.setAttribute('id', 'payture-widget-iframe-wrapper'),
        (c = document.createElement('div')).setAttribute(
          'id',
          'payture-widget-close',
        ),
        (o = document.createElement('div')).setAttribute(
          'id',
          'payture-widget-close-button',
        ),
        c.appendChild(o),
        (r = document.createElement('iframe')).setAttribute(
          'id',
          'payture-widget-iframe',
        ),
        r.setAttribute('frameborder', '0'),
        r.setAttribute('scrolling', 'yes'),
        r.setAttribute('allowfullscreen', 'true'),
        i.appendChild(c),
        i.appendChild(r),
        t.appendChild(s),
        t.appendChild(i),
        document.body.appendChild(d),
        l &&
          0 ==
            (function () {
              function e(e, t, i) {
                return e.hasAttribute(t) && e.getAttribute(t) == i
              }
              for (
                var t = !1, i = document.getElementsByTagName('meta'), a = 0;
                a < i.length;
                a++
              ) {
                var n = i[a]
                if (
                  e(n, 'name', 'viewport') &&
                  e(n, 'content', 'width=device-width, initial-scale=1')
                ) {
                  t = !0
                  break
                }
              }
              return t
            })())
      ) {
        var a = document.createElement('meta')
        a.setAttribute('id', 'viewport-widget-device-width'),
          a.setAttribute('name', 'viewport'),
          a.setAttribute('content', 'width=device-width, initial-scale=1'),
          document.head.appendChild(a)
      }
    })(),
    (function (e) {
      for (var t in ((u.Data = {}), e)) {
        var i = e[t]
        if (
          (('string' != typeof i &&
            'number' != typeof i &&
            'object' != typeof i) ||
            (u.Data[t] = i),
          'ontransactioncompleted' === t.toLowerCase() &&
            'function' == typeof i)
        ) {
          var a = i.bind(u)
          u.OnTransactionCompleted = function (e) {
            a(e)
          }
        }
      }
      for (var t in n) u.Data.hasOwnProperty(t) || (u.Data[t] = n[t])
    })(e),
    d && ((d.style.display = 'block'), (d.style.opacity = 1)),
    r &&
      (r.onload = function () {
        i ||
          ((r.className = 'loaded'),
          (c.className = 'loaded'),
          s && (s.style.visibility = 'hidden'),
          c && (c.style.visibility = 'visible'),
          r && (r.style.visibility = 'visible'))
      }),
    o &&
      (o.onclick = function () {
        p(),
          (this.cleanTimer = setTimeout(function () {
            m.detached || PaytureWidget.EventDispatcher.detachListener(m), h()
          }, t))
      }),
    (m.id = this.id),
    PaytureWidget.EventDispatcher.attachListener(m),
    (function () {
      var e = a
      for (var t in u.Data) {
        var i = u.Data[t]
        ;('string' != typeof i &&
          'number' != typeof i &&
          'boolean' != typeof i) ||
          (e += t.toLowerCase() + '=' + i + '&'),
          'object' == typeof i &&
            ('ChequeParams' == t &&
              (i.hasOwnProperty('CustomerContact') || (i.CustomerContact = ''),
              i.hasOwnProperty('Message') || (i.Message = 'Payture Cheque')),
            (e += t.toLowerCase() + '=' + JSON.stringify(i) + '&'))
      }
      r && (r.src = e)
    })()
}
PaytureWidget.EventDispatcher = {
  occupied: !1,
  queue: [],
  detachListener: function (e) {
    if (
      (window.removeEventListener('message', e),
      (e.detached = !0),
      0 === this.queue.length)
    )
      this.occupied = !1
    else {
      var t = this.queue.pop()
      window.addEventListener('message', t)
    }
  },
  attachListener: function (e) {
    this.occupied
      ? (this.queue = [e].concat(this.queue))
      : (window.addEventListener('message', e), (this.occupied = !0))
  },
}

export default PaytureWidget
